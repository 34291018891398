import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap } from 'components/common';
import Star from 'components/common/Icons/Star';
import Fork from 'components/common/Icons/Fork';
import { Wrapper, Grid, Item, Content, Stats, Languages } from './styles';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);
  const articles = [
    {
      id: 1,
      title: "Unmasking Dejitaru Tsuka I",
      author: "KW",
      isSeries: true,
      url: "https://medium.com/@KW555/unmasking-dejitaru-tsuka-497569b259db",
      description: "\"Unmasking Dejitaru Tsuka\" delves into the mysterious world of TSUKA, a meme coin with a hidden depth that sets it apart in the cryptocurrency realm. Through exploring the identity of the anonymous developer Ryoshi and the community's active role in research and utility, the article highlights the unique narrative and significance of TSUKA in the digital currency space."
    },
    {
      id: 2,
      title: "Unmasking Dejitaru Tsuka II",
      author: "KW",
      isSeries: true,
      url: "https://medium.com/@KW555/unmasking-dejitaru-tsuka-part-ii-717daf500aef",
      description: "The article explores the connections between Tsuka, HOSHI, and Elon Musk, highlighting key transactions, Twitter threads, and research findings that suggest Elon's involvement in the cryptocurrency ecosystem."
    },
    // {
    //   id: 3,
    //   title: "Unmasking Dejitaru Tsuka",
    //   url: "https://medium.com/@KW555/unmasking-dejitaru-tsuka-497569b259db"
    // },
    // {
    //   id: 4,
    //   title: "Unmasking Dejitaru Tsuka",
    //   url: "https://medium.com/@KW555/unmasking-dejitaru-tsuka-497569b259db"
    // },
    // {
    //   id: 5,
    //   title: "Unmasking Dejitaru Tsuka",
    //   url: "https://medium.com/@KW555/unmasking-dejitaru-tsuka-497569b259db"
    // },
    // {
    //   id: 6,
    //   title: "Unmasking Dejitaru Tsuka",
    //   url: "https://medium.com/@KW555/unmasking-dejitaru-tsuka-497569b259db"
    // }
  ];
  const seriesUI =
    <Stats theme={theme}>
    <div>
      <Fork color={theme === "light" ? "#000" : "#fff"} />
      <span>{"Series"}</span>
    </div>
    </Stats>

  return (
    <Wrapper as={Container} id="research">
      <h2>Articles</h2>
      <Grid>
        {articles.map((article ) => (
          <Item key={article.id} as="a" href={article.url} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{article.title}</h4>
                <p>{article.description}</p>
              </Content>
              <TitleWrap>
                <Stats theme={theme}>
                  <div>
                    <Star color={theme === "light" ? "#000" : "#fff"} />
                    <span>{"Author: " + article.author}</span>
                  </div>
                </Stats>
                {article.isSeries && seriesUI}
              </TitleWrap>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};
